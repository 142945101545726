// Scroll Magic
function scrolltime() {
    //DEV MODE
    var DEVMODE = false;
    //Scroll Magic Controller
    var sm_cont = new ScrollMagic.Controller({});

    //Fade In Universal
    var sm_e_fade = $(".scroll-fade");
    if (sm_e_fade.length) {
        sm_e_fade.each(function() {
            var tl_u = new TimelineMax({});
            tl_u.add(TweenMax.staggerFromTo(sm_e_fade, 0.6, { opacity: "0" }, { opacity: "1" }, 0.3));
            var sm_s_u = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 300 })
                .setTween(tl_u)
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_u.addIndicators({ name: "scroll-fade", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //Bounce In Universal
    var sm_e_bounce = $(".scroll-bounce");
    if (sm_e_bounce.length) {
        sm_e_bounce.each(function() {
            var sm_s_bounce = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 200 })
                .setTween(TweenMax.staggerFromTo(sm_e_bounce.children("span"), 1, { top: "-300px" }, { top: "0px", ease: Bounce.easeOut }, 0))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_bounce.addIndicators({ name: "scroll-bounce", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }
    //Universal Scale
    var sm_e_scale = $(".scroll-scale");
    if (sm_e_scale.length) {
        sm_e_scale.each(function() {
            var sm_s_scale = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 200 })
                .setTween(TweenMax.staggerFromTo(sm_e_scale, 1, { transform: 'scale(0)' }, { transform: 'scale(1)' }, 0))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_scale.addIndicators({ name: "scroll-scale", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //universal parallax
    var sm_e_parallax = $(".section-parallax");
    if (sm_e_parallax.length) {
        sm_e_parallax.each(function() {
            var sm_s_parallax = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", duration: "200%" })
                .setTween(TweenMax.to(sm_e_parallax.children(".p_image"), 1, { y: '-40%', autoAlpha: 1, ease: Power0.easeNone }))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_parallax.addIndicators({ name: "parallax", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //Sidebar sticky footer
    function updateSticky(e) {
        var height = $(document).height();
        var windowh = $(window).height();
        var windowTop = $(window).scrollTop(); // returns number
        var sideHeight = $('#border_left').outerHeight();
        var footerHeight = $('#layout_footer').outerHeight();
        var copyHeight = $('#copy').outerHeight();
        var docheight = height - windowh;
        var bottomdist = docheight - windowTop;
        var footh = footerHeight + copyHeight;
        var minheight = sideHeight - footh;
        var footnew = bottomdist - minheight;
        //console.log("minheight: " + minheight + " bottomdist : " + bottomdist + " footnew: " + footnew);
        if (bottomdist < minheight) {
            //console.log("changeto: " + footnew);
            $("#border_left").css('top', footnew + 'px');
        } else {
            $("#border_left").css('top', '0px');
        }
    }

    //Sidebar
    var sm_s_side = new ScrollMagic.Scene({ triggerElement: '#border_r', triggerHook: "onLeave" })
        .setClassToggle("#border_left", "sticky")
        .addTo(sm_cont);
    var sm_s_side2 = new ScrollMagic.Scene({ triggerElement: '#layout_footer', triggerHook: "onEnter" })
        .on("update", updateSticky)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_side.addIndicators({ name: "Side Nav", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //Options Sidebar
    /*var sm_s_options = new ScrollMagic.Scene({ triggerElement: '#css3-tab-panel', triggerHook: "onLeave" })
        .setClassToggle("#css3-tab-panel", "sticky")
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_options.addIndicators({ name: "Options Nav", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };*/

    //Quote Total
    var sm_s_total = new ScrollMagic.Scene({ triggerElement: '#quotebox', triggerHook: "onLeave" })
        .setClassToggle("#quote-top", "sticky")
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_total.addIndicators({ name: "Quote-top", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //Options
    var sm_s_options = new ScrollMagic.Scene({ triggerElement: '#options-wrap', triggerHook: "onLeave" })
        .setClassToggle("#options-top", "sticky")
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_options.addIndicators({ name: "Options Top", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //Gallery
    var sm_s_gallery = new ScrollMagic.Scene({ triggerElement: '#gallery_box', triggerHook: "onLeave" })
        .setClassToggle("#gallery-top", "sticky")
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_gallery.addIndicators({ name: "Gallery Top", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
};
$(document).ready(function() {
    scrolltime();
});

//MOBILE CHECK
var MobChk = {};
$(document).ready(function() {
    // Check the width of the screen
    MobChk.winW = $(window).width();
    // Check if it's a touch screen (based on Modernizr) - Remove if it if you want    
    MobChk.isTouch = false;
    if ($('.touch')[0]) {
        MobChk.isTouch = true;
    }
    // If the size of the screen is lower or equal to 767, we are on a mobile device
    MobChk.isMobile = false;
    if (MobChk.winW <= 767) {
        MobChk.isMobile = true;
    }
    // If we are not on a mobile device, initiate scripts
    if (!MobChk.isMobile) {
        //add scripts here
        //scrolltime();
    }
});
// Disable or enable skrollr on window resize
$(window).resize(function() {
    console.log("RESIZE");
    MobChk.winW = $(window).width();
    if (MobChk.winW <= 767) {
        console.log("MOBILE");
        MobChk.isMobile = true;
        //add mobile scripts here

    } else {
        console.log("NOT MOBILE");
        //add scripts here
        //scrolltime();

    }
});