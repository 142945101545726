// JavaScript Document
//alert( 'Script Is Enqueued' )



function find_page_number(element) {
    element.find('span').remove();
    return parseInt(element.html());
}

function find_page_number_nav(element) {
    var currentpage = $("span.current").html();
    var currentpageclean = parseInt(currentpage);
    element.find('span').remove();
    if ($(element).hasClass("next")) {
        return currentpageclean + 1;
    } else if ($(element).hasClass("prev")) {
        return currentpageclean - 1;
    } else {
        return parseInt(element.html());
    };
}

$(document).on('click', '#ajax-posts-wrap .nav-links a', function(event) {
    event.preventDefault();

    var page = find_page_number_nav($(this).clone());
    console.log("ajax posts", page);
    $.ajax({
        url: app.ajaxurl,
        type: 'post',
        data: {
            action: 'ajax_pagination',
            query_vars: app.query_vars,
            page: page
        },
        beforeSend: function() {
            $('#ajax-posts-wrap').find('article').remove();
            $('#ajax-posts-wrap nav').remove();
            $('html, body').animate({ scrollTop: 0 }, 1000);
            $('#ajax-posts-wrap').append('<div class="post-loader-wrap loader"></div>');
        },
        success: function(html) {
            $('#ajax-posts-wrap .loader').addClass("fadeout");
            setTimeout(function() {
                $('#ajax-posts-wrap .loader').remove();
            }, 750);
            $('#ajax-posts-wrap').append(html);
            //refresh social share
            //wpz_social_share_open();
        }
    })
})