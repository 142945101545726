$(document).ready(function() {

    if ($("#grid_box").length) {
        var grid_box = document.getElementById("grid_box");
        var boxcount = 100;
        var min = 1;
        var max = 5;
        var griditems = [];
        for (i = 0; i < boxcount; i++) {
            var div = document.createElement('div');
            var boxid = i;
            var num = Math.floor(Math.random() * (max - min + 1)) + min;
            div.setAttribute('id', boxid);
            div.setAttribute('class', 'grid_slot_' + num);
            var inner = '<span></span>';
            div.innerHTML = inner;
            grid_box.appendChild(div);
            //$(div).hide().fadeIn(1000);
            var span = $(div).children();
            griditems.push(span);
        }
        TweenMax.staggerFromTo(griditems, 0.20, { opacity: "0", top: -100 }, { opacity: "1", top: 0 }, .1);
    };




    var sliderele = '';
    if ($("#header_slider").length) {
        var templateUrl = themevars.templateUrl;
        var assetsdir = templateUrl + "/assets/img/parts/"
            //Slides
        var slidesjson = [{
            "id": 1,
            "active": true,
            "photo_url": "header01.jpg",
            "photo_url_mobile": "header01_mobile.jpg"
        }, {
            "id": 2,
            "active": true,
            "photo_url": "header02.jpg",
            "photo_url_mobile": "header02_mobile.jpg"
        }, {
            "id": 3,
            "active": true,
            "photo_url": "header03.jpg",
            "photo_url_mobile": "header03_mobile.jpg"
        }, {
            "id": 4,
            "active": true,
            "photo_url": "header04.jpg",
            "photo_url_mobile": "header04_mobile.jpg"
        }];
        //var slidesData = slidesjson;
        var slidesData = slidesjson;
        var slidesel = document.getElementById("header_slider");
        var slidesinner = document.getElementById("slider-wrap");
        var activeslides = [];
        slidesData.forEach(function(slide) {
            if (slide.active && slide.photo_url) {
                activeslides.push(slide);
            }
        });

        function shuffle(json) {
            for (var i = 0; i < json.length - 1; i++) {
                var j = i + Math.floor(Math.random() * (json.length - i));
                var temp = json[j];
                json[j] = json[i];
                json[i] = temp;
            }
            return json;
        }
        //Add Slides HTML
        function loadslider(activeslides) {
            var shuffled = shuffle(activeslides);
            for (i = 0; i < shuffled.length; i++) {
                var div = document.createElement('div');
                var slide = shuffled[i];
                var lastc = '';
                if (i == (shuffled.length - 1)) { lastc = 'last'; };
                div.setAttribute('id', 'slide-' + slide.id);
                div.setAttribute('class', 'slide');
                var inner = '<picture><source media="(max-width: 992px)" srcset="' + assetsdir + slide.photo_url_mobile + '"><img src="' + assetsdir + slide.photo_url + '" alt="Hero"></picture>';
                div.innerHTML = inner;
                slidesinner.appendChild(div);
                $(div).hide().fadeIn(1000);
            }
        }
        if (slidesel) { loadslider(activeslides); };

        /* Slider rotation */
        var children = '';
        var slides = 0;
        var slideIprev = 0;
        var slideI = 0;
        var Autoplaytimer = 25000;
        var slidetimer = '';
        var transitiontime = 3000;

        //Slider Functions
        function initslider() {
            children = $("#slider-wrap").children();
            slides = children.length;
            slideI = 0;
            $(children[slideI]).addClass("active");
            //autoplay init
            autoplayslide();
        }

        function updateslide(slideIprev, slideI) {
            //add fadeout animation
            $(children[slideIprev]).addClass("fadeout");
            //add fadein animation
            $(children[slideI]).addClass("fadein");
            //update active after transition
            setTimeout(function() {
                $("#slider-wrap>div").removeClass("active");
                $(children[slideI]).addClass("active");
            }, (transitiontime));
            //clean fades after transition
            setTimeout(function() {
                $("#slider-wrap>div").removeClass("fadeout fadein");
            }, (transitiontime));
            autoplayreset();
        }

        function autoplayslide() {
            slidetimer = setTimeout(nextslide, Autoplaytimer);
        };

        function autoplayreset() {
            clearTimeout(slidetimer);
            autoplayslide();
        }

        function nextslide() {
            slideIprev = slideI;
            slideI++;
            if (slideI >= slides) { slideI = 0; };
            //console.log("Next:" + slideI);
            updateslide(slideIprev, slideI);
        }

        function prevslide() {
            slideIprev = slideI;
            slideI = slideI - 1;
            if (slideI < 0) { slideI = (slides - 1); };
            //console.log("Prev:" + slideI);
            updateslide(slideIprev, slideI);
        }

        //Init Slider
        initslider();

    };
});