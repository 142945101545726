// JavaScript Document
//alert( 'Script Is Enqueued' )

$('#gallery-top a').click(function(event) {
    event.preventDefault();

    $('#gallery-top .inner').children().removeClass('active');
    $(this).addClass("active");

    var id = this.getAttribute('data-id');
    var slug = this.getAttribute('data-slug');

    $.ajax({
        url: app.ajaxurl,
        type: 'post',
        data: {
            action: 'ajax_gallery_cat',
            query_vars: app.query_vars,
            id: id,
            slug: slug
        },
        beforeSend: function() {
            $('#gallery-inner').children().remove();
            $('html, body').animate({ scrollTop: 0 }, 1000);
            $('#gallery-inner').append('<div class="page-content loader"><div class="loader-wrap"><div class="loader-icon-spin"><div class="spinner_wrapper"><div class="inner"></div><div class="triangle"></div><div class="triangle"></div><div class="triangle"></div></div></div></div><span>Loading New Posts...</span></div>');
        },
        success: function(html) {
            $('#gallery-top').toggleClass("active");
            $('#gallery-inner .loader').remove();
            $('#gallery-inner').append(html);
            //start lazyload
            var myLazyLoad = new LazyLoad();
        }
    })


});