// JavaScript Document
//alert( 'Script Is Enqueued' )

$('#options-top a').click(function(event) {
    event.preventDefault();

    $('#options-top .inner').children().removeClass('active');
    $(this).addClass("active");

    var slug = this.getAttribute('data-slug');

    $.ajax({
        url: app.ajaxurl,
        type: 'post',
        data: {
            action: 'ajax_options_cat',
            query_vars: app.query_vars,
            slug: slug
        },
        beforeSend: function() {
            $('#options-inner').children().remove();
            $('html, body').animate({ scrollTop: 0 }, 1000);
            $('#options-inner').append('<div class="page-content loader"><div class="loader-wrap"><div class="loader-icon-spin"><div class="spinner_wrapper"><div class="inner"></div><div class="triangle"></div><div class="triangle"></div><div class="triangle"></div></div></div></div><span>Loading New Posts...</span></div>');
        },
        success: function(html) {
            $('#options-top').toggleClass("active");
            $('#options-inner .loader').remove();
            $('#options-inner').append(html);
            //start lazyload
            var myLazyLoad = new LazyLoad();
            //reset lightbox
            var lightbox = $('.options-inner .group').simpleLightbox({
                sourceAttr: 'href',
                overlay: true,
                spinner: true,
                nav: true,
                navText: ['<', '>'],
                captions: true,
                captionSelector: 'span',
                captionType: 'attr',
                captionsData: 'title',
                captionPosition: 'bottom',
                captionDelay: 0,
                close: true,
                closeText: '×',
                swipeClose: true,
                showCounter: true,
                fileExt: 'png|jpg|jpeg|gif',
                animationSpeed: 250,
                animationSlide: true,
                preloading: true,
                enableKeyboard: true,
                loop: true,
                rel: false,
                docClose: true,
                swipeTolerance: 50,
                className: 'simple-lightbox',
                widthRatio: 0.8,
                heightRatio: 0.9,
                disableRightClick: false,
                disableScroll: false, //when enabled adds padding to right
                alertError: true,
                alertErrorMessage: 'Image not found, next image will be loaded',
                additionalHtml: false,
                history: false //throws error
            });
        }
    })


});